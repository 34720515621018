
import EventCard from "@/components/EventCard.vue"; // @ is an alias to /src
import { defineComponent } from "vue";
import EventService from '@/services/EventService'

export default defineComponent({
  name: 'EventList',
  components : {
    EventCard
  },
  data(){
    return {
      events: null
    }
  },
  created() {
    EventService.getEvents()
    .then(response => {
      this.events = response.data      
    })
    .catch(error => {
        console.log(error);
        }
    )
  }
})
