<template>
    <div v-if="event">
        <h1> {{event.title}} </h1>       
        <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
        <p> {{event.location}} </p>
    </div>    
</template>

<script lang="ts">
import EventService from '@/services/EventService'
import Vue, { defineComponent } from 'vue'

export default defineComponent({
    props: {
        id: {
            type: Number,
            required: true
        }
    },
    data() {        
        return {
            event: null
        }
    },
    created() {
        EventService.getEvent(this.id)
        .then(response => {
        this.event = response.data      
        })
        .catch(error => {
            console.log(error);
            }
        )
    }
})
</script>