
import EventService from '@/services/EventService'
import Vue, { defineComponent } from 'vue'

export default defineComponent({
    props: {
        id: {
            type: Number,
            required: true
        }
    },
    data() {        
        return {
            event: null
        }
    },
    created() {
        EventService.getEvent(this.id)
        .then(response => {
        this.event = response.data      
        })
        .catch(error => {
            console.log(error);
            }
        )
    }
})
