
import { defineComponent } from "vue";
import { Options, Vue } from "vue-class-component";

export default defineComponent ({
  name: 'EventCard',
  props: {
    event: Object
  }
 })
